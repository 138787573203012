import React from "react";

function FAQ() {
  return (
    <div>
      <h2>FAQ Page</h2>
      {/* Add frequently asked questions and answers here */}
    </div>
  );
}

export default FAQ;
